<template>
  <div>
    <v-app-bar app elevation="0" class="white">
      <v-btn v-if="mode ==='view'" icon @click="$router.push({name: 'user'})"><v-icon color="primary">mdi-arrow-left</v-icon></v-btn>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12">
            <v-card tile elevation="0">
              <v-card-text v-html="data.contentTh"> </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-main>
    <v-footer app height="">
      <v-row justify="center" no-gutters>
        <v-col cols="12">
          <div>
            <v-checkbox v-model="agree" :label="'ยอมรับทั้งหมด'"></v-checkbox>
          </div>
        </v-col>
         <v-btn v-if="mode ==='view'" x-large height="58"  block color="primary" @click="submit">ตกลง</v-btn>
        <v-btn v-else :disabled="!agree" x-large height="58"  block color="primary" @click="submit">ตกลง</v-btn>
        <v-col cols="12">
          <div class="text-center"></div>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import { pdpaContent, consent } from "@/api";
import { mapFields } from "vuex-map-fields";

export default {
  props: {
    mode: {
      type: String
    }
  },
  data() {
    return {
      data: "",
      loading: false,
      role: null,
      agree: false
    };
  },
  computed: {
    ...mapFields(['tou'])
  },
  methods: {
    submit() {
      this.loading = true
      this.role = this.$offlineStorage.get("role");
      let data = {
        contentId: this.data.id,
        contentType: this.data.contentType,
        version: this.data.version,
        versionDate: this.data.versionDate,
        isAccept: this.agree,
      };

      consent(
        data,
        (message) => {
          if (message.data.code === 1) {
              this.tou = true
              let role = this.$offlineStorage.get("role");
              if(role.consent.privacyPolicy && role.consent.privacyPolicy.isAccept){
                 this.$offlineStorage.set("isAuthen", true);
                 this.$auth.buildAllSite()
              }else {
                 this.$router.replace({name: 'pn', params: { mode: 'consent'}});
              }
          } 
          this.loading = false
        },
        (error) => {
          console.log(error);
          this.loading = false
        }
      );
    },
    fetchData() {
      this.loading = true;
      console.log(this.$auth.role)
      if (this.mode === 'view') this.agree = this.$auth.role.consent.termCondition.isAccept
      pdpaContent(
        "term_condition",
        (message) => {
          if (message.data.code === 1) {
            this.data = message.data.result[0];
            //console.log(message.data.result[0])
          } else {
            this.data = [];
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(error);
        }
      );
    },
  },
  mounted() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  border-radius: 6px;
}
</style>